.appNav {
  position: relative !important;
  padding: 0px 25px !important;
  top: -10px;
  z-index: 999 !important;
  background-color: #ffffff !important;
  width: 60% !important;
  margin: auto !important;
  clip-path: polygon(10% 0%, 90% 0%, 100% 50%, 90% 100%, 10% 100%, 0% 50%); 
}

@media only screen and (max-width: 767px) {
  .appNav {
  width: 100% !important;
   top: -20px;
}

}
.link {
  color: #000;
  text-decoration: none;
  transition: 0.5s;
}
.link:hover {
  color: #fccc24;
  text-decoration: underline;
}

body,
* {
  font-family: "Cairo", sans-serif !important; /* الافتراضي للعربية */
}

/* اللغة الإنجليزية */
body:lang(en),
*:lang(en) {
  font-family: "Cairo", sans-serif !important;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  z-index: 2;
}

footer {
  position: relative;
  background: #262626;
  background-size: cover;
  padding: 16px 64px;
}

footer input {
  background-color: rgb(0, 0, 0, 30%);
  width: 100%;
  height: 45px;
  border: none !important;
  outline: none !important;
  padding: 0 35px;
  border-radius: 8px;
  color: #fff;
  margin: 10px 0;
}

footer input::placeholder {
  color: #fff;
}

footer .link {
  color: #fff;
}

@media only screen and (max-width: 767px) {
  footer {
    padding: 16px 24px;
  }
}
